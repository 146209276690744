 /* Container holding the image and the text */
.schedule-container {
    position: relative;
}
  
/* Bottom right text */
.schedule-text {
    position: absolute;
    bottom: 0%;
    left: 0%;
    height: 45%;
    width: 80%;
    background-color: rgb(230, 248, 167);
    color: black;
    font-size: 1.2vw;
    padding-left: 1%;
    padding-right: 1%;
}