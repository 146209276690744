.topnavBlog {
    background-color: white;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
  }
  
  /* Style the links inside the navigation bar */
  .topnavBlog a {
    display: inline-block;
    color: black;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-family: "Brush Script MT";
    font-size: 2.5vw;
    font-weight: bold;
    font-style: italic;
  }
  
  /* Change the color of links on hover */
  .topnavBlog a:hover {
    background-color: #ddd;
    color: black;
  }
  
  /* Add an active class to highlight the current page */
  .topnavBlog a.active {
    color: rgb(185,90,63);
    
  }
  
  /* Hide the link that should open and close the topnav on small screens */
  .topnavBlog .icon {
    display: none;
  } 