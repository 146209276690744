.accordeon-image-homepage {
    height: 500px;
    width: 33%;
}

.latest-posts {
  background-color: white;
  width:90%;
  margin: 5% 5%;
  padding: 1% 0%;
}

.four-image {
  width: 45%;
  height: 350px;
}

.all-posts-button {
    margin-left: 40%;
    margin-bottom: 5%;
    padding: 1%;
    width:20%;
    background-color: black;
    color: rgb(187,217,94);
    font-size: 1.5vw;
    border: none;
}

.four-image-div {
  margin-left: 5%;
}

.accordionFAQ {
  max-width: 80%;
  margin-left: 10%;
  margin-top: 1%;
}

.accordion-titleFAQ {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  background-color: #ddd;
}

.accordion-titleFAQ:hover {
  background-color: #bbb;
}

.accordion-titleFAQ,
.accordion-contentFAQ {
  padding: 1rem;
}

.accordion-contentFAQ {
  background-color: #eee;
}

@media only screen and (max-width: 700px) {
    .image-accordeon {
      display: none;
    }
  }