.two-box-streams {
    width: 90%;
    display: flex;
    justify-content: space-between;
    margin: 5%;
}

.title-container-streams {
    width: 50%;
    align-items: center;
    /* vertical-align: middle; */
    text-align: center;
    display: flex;
}

.title-streams {
    text-align: center;
    font-size: 5vw;
    vertical-align: middle;
    margin-left: 15%;
}

.text-container-streams {
    width: 50%;
    background-color: white;
    padding: 2%;
}

.text-streams {
    width: 100%;
    text-align: center;
}