.slider-channel {
    overflow: hidden;
}
  
.slide-list-channel {
      display: flex;
      height: 100%;
      transition: transform 0.5s ease-in-out;
      width: 50%;
      margin-left: 30%;
}
  
.slide-channel {
        flex: 1 0 100%;
        position: relative;
        padding-right: 10%;
}
  
.slide-video-channel {
  display: flex;
  margin: 0 auto;
  max-height: 300px;
  width: 100%;
  object-fit: contain;
}
  
.slide-title-channel {
  text-align: center;
  margin-top: 10px;
}
  
    /* DOTS */
  
.dots-channel {
      display: flex;
      justify-content: center;
      padding: 20px 0;
      position: relative;
      z-index: 2;
}
  
.dot-channel {
        background-color: white;
        border-radius: 5px;
        height: 10px;
        margin: 5px;
        width: 10px;
}
  
.dot-channel:hover {
  cursor: pointer;
}
  
.dot-channel.selected {
  background-color: black;
}
  
    /* ARROWS */
  
.arrows-channel {
      color: white;
      display: flex;
      justify-content: space-between;
      position: absolute;
      margin-top: 10vh;
      width: 100%;
      z-index: 1;
}
  
.arrow-channel {
        height: 10vh;
        width: 10vw;
}
  
.arrow-channel:hover {
  cursor: pointer;
}
  
.arrow-channel.left {
  background-image: url("../../Utils/images/penisLeft.png"); /*../../../assets/icons/arrow.png);*/
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 5px;
}
  
.arrow-channel.right {
  background-image: url("../../Utils/images/penisRight.png"); /*../../../assets/icons/arrow.png);*/
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 5px;
}