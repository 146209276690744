.topnav {
  background-color: rgb(206,214,181);
  overflow: hidden;
  display: flex;
  justify-content: space-between;
}

/* Style the links inside the navigation bar */
.topnav a {
  float: left;
  display: block;
  color: black;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
  font-weight: bold;
}

/* Change the color of links on hover */
.topnav a:hover {
  background-color: #ddd;
  color: black;
}

/* Add an active class to highlight the current page */
.topnav a.active {
  color: rgb(185,90,63);
  
}

/* Hide the link that should open and close the topnav on small screens */
.topnav .icon {
  display: none;
} 