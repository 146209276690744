.header-footer {
    margin: 0px;
    padding: 2% 0%;
    text-align: center;
}

.footer-form-text {
    max-width:100%;
    width:100%;
    outline: 0;
    background-color: rgb(206,214,181);
    border-width: 0 0 0.2vw;
    border-color: white
}

.footer-form-submit {
    margin-left: 30%;
    padding: 2%;
    width:40%;
    background-color: black;
    color: rgb(187,217,94);
    font-size: 1.5vw;
    border: none;
}

.footer-side-form {
    margin: 2% 30%;
    max-width: 40%;
    color: black;
    border: black;
    padding-bottom: 2%;
}