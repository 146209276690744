.repeater {
    display: inline-flex;
    justify-content: center;
}

.center-header {
    text-align: center;
    padding-top: 2%;
}

.post-coming {
    padding-right: 3%;
    max-width: 20%;
}