.banner-image {
    padding-bottom:2%;
    text-align:center;
    color: white;
    height:300px;
    background-size: 100% 100%;
}

.center {
    margin: 0;
    position: relative;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}