.serie-form {
    padding: 2% 5%;
    background-color: rgb(212,212,212);
    margin: 0% 25%;
    max-width: 40%;
    color: black;
}

.serie-form-text {
    border: none;
    padding: 2%
}

.same-line-feedback {
    display: flex;
    justify-content: space-between;
}

.same-line-separation {
    width: 40%;
    padding-right: 10%;
}

.series-feedback-submit {
    margin-left: 35%;
    padding: 2%;
    width:30%;
    background-color: rgb(42,46,60);
    color: white;
    font-size: 1.5vw;
    border: none;
    margin-top: 15%;
}

.rate {
    float: left;
    height: 46px;
    padding: 0 10px;
}
.rate:not(:checked) > input {
    position:absolute;
    top:-9999px;
}
.rate:not(:checked) > label {
    float:right;
    width:1em;
    overflow:hidden;
    white-space:nowrap;
    cursor:pointer;
    font-size:30px;
    color:black;
}
.rate:not(:checked) > label:before {
    content: '★ ';
}
.rate > input:checked ~ label {
    color: #ffc700;    
}
.rate:not(:checked) > label:hover,
.rate:not(:checked) > label:hover ~ label {
    color: #deb217;  
}
.rate > input:checked + label:hover,
.rate > input:checked + label:hover ~ label,
.rate > input:checked ~ label:hover,
.rate > input:checked ~ label:hover ~ label,
.rate > label:hover ~ input:checked ~ label {
    color: #c59b08;
}

/*selecting*/
input:nth-of-type(5):checked  ~ .text:before{
    content:"Not bad";
}

label:nth-of-type(5):hover  ~ .text:before{
    content:"Not bad"!important;
}

input:nth-of-type(4):checked  ~ .text:before{
    content:"Its Ok";
}

label:nth-of-type(4):hover  ~ .text:before
{
    content:"Its Ok"!important;
}


input:nth-of-type(3):checked  ~ .text:before{
    content:"Good!";
}

label:nth-of-type(3):hover  ~ .text:before{
    content:"Good!"!important;
}


input:nth-of-type(2):checked  ~ .text:before{
    content:"Very Good!";
}
label:nth-of-type(2):hover  ~ .text:before{
    content:"Very Good!"!important;
}

input:nth-of-type(1):checked  ~ .text:before{
    content:"Awesome!!";
}
label:nth-of-type(1):hover  ~ .text:before{
    content:"Awesome!!"!important;
}