.form-element {
    max-width: 100%;
    font-size: 2vw;
}

.side-form-fields {
    padding: 10%;
    background-color: rgb(187,217,94);
    margin: 5%;
    max-width: 100%;
    color: black;
}

.side-form-text {
    max-width:100%;
    background-color: rgb(187,217,94);
    outline: 0;
    border-width: 0 0 0.2vw;
    border-color: white
}

.side-form-submit {
    margin-left: 10%;
    padding: 10%;
    width:80%;
    background-color: black;
    color: rgb(187,217,94);
    font-size: 1.5vw;
    border: none;
}