a.button-side-widget {
    background-color: transparent;
    padding: 5% 10%;
    left: 15%;
    position: relative;
    width: 50%;
    display: block;
    text-align: center;
    color: black;
    font-style: normal;
    border: 1px solid black;
    text-decoration: none;
}