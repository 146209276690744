.side-widget{
    max-width: 24%;
    margin-left: 3%;
    margin-right: 3%;
    margin-top: 2%;
    margin-bottom: 2%;
    height: 100%;
    background-color: rgb(228,240,191);
}

.side-element {
    padding: 10%;
}

.side-form {
    padding: 10%;
    background-color: black;
    margin: 5%;
    max-width: 90%;
    color: white;
}