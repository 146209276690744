.series-container {
    position: relative;
    text-align: center;
}

.series-image {
    max-width: 100%;
    max-height: 100%;
}

.series-info {
    position: absolute;
    top: -10%;
    left: 0%;
    color: white;
    font-size: 1.3vw;
    padding-left: 1%;
    padding-right: 1%;
}

.series-title {
    color: white;
    font-size: 1.7vw;
}

.series-info {
    color: white;
    font-size: 1vw;
}

