.episode-image {
    max-width: 90%;
    max-height: 70%;
    float: inline-start;
}

.episode-info {
    width: 88%;
    background-color: white;
    color: black;
    font-size: 1.3vw;
    padding-left: 1%;
    padding-right: 1%;
}

.episode-container {
    display: inline-block;
}

.episode-header {
    margin-top: 0%;
    padding: 5%;
    padding-bottom: 0%;
}

.episode-description {
    padding: 5%;
    padding-top: 0%;
}